import React from 'react';
import { graphql } from 'gatsby';
import { FormBlock } from '../acf/FormBlock/FormBlock.jsx';
import Layout from '../components/Layout';
import { getUrlVars } from '../utils/helpers';
import SEO from '../components/SEO';

const ProductEnquiryPage = ({ location, data}) => {
  const {
    site: {
      siteMetadata: {
        title: siteTitle
      }
    }
  } = data;
  const vars = getUrlVars();
  return (
    <Layout location={location}>
      <SEO title={`Product Enquiry | ${siteTitle}`} />
      <FormBlock
        smallHeading="Enquire"
        heading="Product Enquiry Form"
        location={location}
        form="product"
        partNumber={decodeURIComponent(vars.part || 'null')}
        partDescription={decodeURIComponent(vars.desc || 'null')}
      />
    </Layout>
  );
}

export default ProductEnquiryPage

export const pageQuery = graphql`
  query ProductEnquiryQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
